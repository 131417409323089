import React, { Component } from "react";
//import Zmage from "react-zmage";
import Fade from "react-reveal";

//let id = 0;
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects) {
      //     let projectImage = "images/portfolio/" + projects.image;

      return (
        // <div key={id++} className="columns portfolio-item">
        //   <div className="item-wrap">
        //     <Zmage alt={projects.title} src={projectImage} />
        //     <div style={{ textAlign: "center" }}>{projects.title}</div>
        //   </div>

        // </div>
        <div style={{ "margin-left": "5px", "margin-right": "-80px" }}>
          <div style={{ "margin-right": "30px", "float": "left" }}>
            <iframe width="320" height="180" src="https://www.youtube.com/embed/RF6_e73IvPs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" style={{ marginRight: "50" }}></iframe>      </div>
          <div style={{ "margin-right": "30px", "float": "left" }}>
            <iframe width="320" height="180" src="https://www.youtube.com/embed/iMMa5WUrMk4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" style={{ marginRight: "50" }}></iframe> </div>
          <div style={{ "margin-right": "30px", "float": "left" }}>
            <iframe width="320" height="180" src="https://www.youtube.com/embed/HD12k4eu1xA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" style={{ marginRight: "50" }}></iframe> </div>
          <h1 style={{ "margin-bottom": "20px", "margin-right": "80px", "float": "center" }} >YouTube Automation</h1>
          <div style={{ "margin-right": "30px", "float": "left" }}>
            <iframe width="320" height="180" src="https://www.youtube.com/embed/QETGux29gUM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" ></iframe>    </div>
          <div style={{ "margin-right": "30px", "float": "left" }}>
            <iframe width="320" height="180" src="https://www.youtube.com/embed/IJz2Iyr_IUY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>       </div>
          <div style={{ "margin-right": "30px", "float": "left" }}>
            <iframe width="320" height="180" src="https://www.youtube.com/embed/hapGM6UUo6M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>     </div>

          <h1 style={{ "margin-bottom": "20px", "margin-right": "80px", "float": "center" }} >Other Videos</h1>
          <div style={{ "margin-right": "30px", "float": "left" }}>
            <iframe width="320" height="180" src="https://www.youtube.com/embed/wQ5St7WB0Us" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" ></iframe>  </div>
          <div style={{ "margin-right": "30px", "float": "left" }}>
            <iframe width="320" height="180" src="https://www.youtube.com/embed/_O7WZXgBrBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" ></iframe>   </div>
          <div style={{ "margin-right": "30px", "float": "left" }}>
            <iframe width="320" height="180" src="https://www.youtube.com/embed/LyV_oh3OmH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" ></iframe> </div>

        </div>






      );
    });

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Check Out Some of Our Works.</h1>
              <h1 style={{ "margin-bottom": "20px" }}>Video Editing</h1>
              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects}
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
